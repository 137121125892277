const {
    // API start
    REACT_APP_API_URL = "http://localhost:3004/webapi/",
    REACT_APP_BACKEND_URL = "http://localhost:3004/",
    REACT_APP_SOCKET_URL = "http://localhost:3004/",
    REACT_APP_CAPTCHA_STATUS = "Disable",
    REACT_APP_BANK_PAYMENT_EDIT = "Disable",
    REACT_APP_UPI_PAYMENT_EDIT = "Disable",
    REACT_APP_TRADE_STATUS = "Disable",
    REACT_APP_P2P_STATUS = "Disable",
    REACT_APP_COMPETITION_STATUS = "Disable",
    REACT_APP_NOTIFICATION_STATUS = "Disable",
    REACT_APP_APIDOC_STATUS = "Disable",
    // API end
    // Site detail start
    REACT_APP_FRONTEND_URL = "http://localhost:3000",
    REACT_APP_FRONEND_URL_PRINT = "exchange.test.com",
    REACT_APP_SITENAME = "BitproEX",
    REACT_APP_THEME_NAME = "siteTheme",
    // Site detail end
    // Status start
    REACT_APP_NFT_STATUS = "Disable",
    REACT_APP_STAKING_STATUS = "Disable",
    // Status end
} = process.env;

const KEY = {
    // API start
    V1_API_URL: REACT_APP_API_URL + "v1/",
    V2_API_URL: REACT_APP_API_URL + "v2/",
    BACKEND_URL: REACT_APP_BACKEND_URL,
    SOCKET_URL: REACT_APP_SOCKET_URL,
    CAPTCHA_STATUS: REACT_APP_CAPTCHA_STATUS,
    PAYMENT_BANK_EDIT: REACT_APP_BANK_PAYMENT_EDIT,
    PAYMENT_UPI_EDIT: REACT_APP_UPI_PAYMENT_EDIT,
    TRADE_STATUS: REACT_APP_TRADE_STATUS,
    P2P_STATUS: REACT_APP_P2P_STATUS,
    COMPETITION_STATUS: REACT_APP_COMPETITION_STATUS,
    NOTIFICATION_STATUS: REACT_APP_NOTIFICATION_STATUS,
    APIDOC_STATUS: REACT_APP_APIDOC_STATUS,
    // API end
    // Site detail start
    FRONEND_URL: REACT_APP_FRONTEND_URL,
    FRONEND_URL_PRINT: REACT_APP_FRONEND_URL_PRINT,
    SITENAME: REACT_APP_SITENAME,
    // Site detail end
    // Status start
    NFT_STATUS: REACT_APP_NFT_STATUS,
    STAKING_STATUS: REACT_APP_STAKING_STATUS,
    // Status end
    THEME_NAME: REACT_APP_THEME_NAME,
    whitepaper: "Whitepaper",
    TFA: {
        downLoadLink: {
            googleApp: "https://apps.apple.com/us/app/google-authenticator/id388497605",
            playstoreApp: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US&pli=1"
        }
    },
    appLinks: {
        googlePlay: "https://play.google.com/store/apps/details?id=com.bitpro.bitproex",
        appStore: ""
    },
    google: {
        recaptcha: {
            SITE_KEY: "",
        }
    },
    cryptoEducation: "https://play.google.com/store/apps/details?id=com.blockproexit.learners",
    coinListing: "https://docs.google.com/forms/d/e/1FAIpQLSdU1i-xT6NM3wHB3AMafsRVWm2VL4KtX3odOh2pwDP9dbZziQ/viewform",
    timer: {
        resendOtp: 120
    },
    nftLink: "",
    stakingLink: "",
    competitionLink: "",
    FanTknSymbol: "BKC",
    depositFiatStatus: "Disable",
    apiPageLink: "https://documenter.getpostman.com/view/31417241/2s9YeEbXJY"
};

export default KEY;