import React, { useEffect, useState } from "react";
import $ from "jquery";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import {
  BsBank2,
  BsArrowRight,
  BsShield,
  BsCurrencyExchange,
  BsCoin,
  BsCurrencyDollar,
  BsCodeSquare,
} from "react-icons/bs";
import { AiFillWallet, AiFillApi, AiFillStop } from "react-icons/ai";
import {
  BsFillEnvelopeCheckFill,
  BsFillEnvelopeExclamationFill,
} from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { ImArrowRight2, ImProfile, ImRocket } from "react-icons/im";
import { BiLogOutCircle } from "react-icons/bi";
import { RiCompass2Line } from "react-icons/ri";
import { SiGamedeveloper } from "react-icons/si";
import { useContextData } from "../../core/context/index";
import { getCookie, deleteCookie } from "../../core/helper/cookie";
import { FaUserAlt } from "react-icons/fa";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { BsBellFill } from "react-icons/bs";

import jwt_decode from "jwt-decode";
import stakeSvg from "../../assets/svg/stake.svg";
import lightnew from "../../assets/images/mode-images/light-new.svg";
import Darknew from "../../assets/images/mode-images/Dark-new.svg";
import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";
import socketIOClient from "socket.io-client";
import { Badge } from "react-bootstrap";
import moment from "moment/moment";

export default function NavbarOne(props) {
  const navigate = useNavigate();
  const { siteSettings, myProfile, setUserProfile } = useContextData();
  const [socketConnection, setSocketConnection] = useState(null);
  const [currNotification, setCurrNotification] = useState([]);
  const [userLogin, setUserLogin] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [unread, setUnread] = useState(0);
  const [unreadData, setUnreadData] = useState([]);
  const [ids, setIds] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (myProfile && myProfile._id) {
      setUserId(myProfile._id);
      if (Config.NOTIFICATION_STATUS == "Enable") {
        fetchNotifications();
      } else {
        loginChk();
      }
    }
  }, [myProfile]);

  useEffect(() => {
    if (socketConnection != null) {
      if (typeof userId == "string" && userId) {
        loadSocket();
      }
    }
  }, [socketConnection]);
  const handleToggle = () => {};
  const loadSocket = async () => {
    var storeIds = [];
    socketConnection.on("notificationSent", function (data) {
      const filterUnread = data.msg.filter(
        (noti) =>
          (noti.status == 0 && noti.userId == userId) || noti.status == 0
      );
      setUnread(filterUnread.length);
      setUnreadData(filterUnread);
      filterUnread.map((send) => {
        if (!send.notificationType) {
          storeIds.push(send._id);
        }
      });
      setIds(storeIds);
    });
  };
  const modesetter = async (event) => {
    const mode = event.target.value;
    props.setTheme(mode);
    localStorage.setItem("mode", mode);
  };

  const loginChk = async (event) => {
    const token = getCookie("userToken");
    const decodedToken = token ? jwt_decode(token, { header: true }) : {};
    if (decodedToken.typ === "JWT") {
      setUserLogin(true);
    }
  };
  const fetchNotifications = async () => {
    try {
      loginChk();
      let socket = socketIOClient(Config.SOCKET_URL, {
        transports: ["websocket"],
      });
      var socketConnection = socket;
      var storeIds = [];
      const params = {
        url: `${Config.V1_API_URL}notification/getAllNotification`,
        method: "POST",
        body: { userId: userId },
      };
      const { status, data, error } = await makeRequest(params);
      if (status == true) {
        const example = data;
        setCurrNotification(example);
        let filterUnread = 0;
        if (data.length > 0) {
          filterUnread = data.filter(
            (noti) =>
              (noti.status == 0 && noti.userId == userId) || noti.status == 0
          );
          if (filterUnread?.length) {
            setUnread(filterUnread.length);
            setUnreadData(filterUnread);
          }

          filterUnread.map((send) => {
            if (send.notificationType) {
              storeIds.push(send._id);
            }
          });
          setIds(storeIds);
        } else {
          filterUnread = 0;
          setUnread(filterUnread);
        }
      } else {
        console.log("Some error occur");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    loginChk();
    window.scrollTo(0, 0);
    if (Config.NOTIFICATION_STATUS == "Enable") {
      let socket = socketIOClient(Config.SOCKET_URL, {
        transports: ["websocket"],
      });
      let socketUnsubscribe;

      if (socket) {
        socket.on("notification", (data) => {
          fetchNotifications();
        });

        socket.on("connect_error", (err) => {
          console.log("socket connect_error", err);
        });
        socket.on("disconnect", function () {
          console.log("socket disconnected");
        });
      }
      return () => {
        if (socketUnsubscribe) {
          socketUnsubscribe.disconnect();
        }
      };
    }
  }, [myProfile]);

  const markAsRead = async (content) => {
    if (content == "same") {
      let notifyId = [...new Set(ids)];
      let payLoad = {
        notifyId,
        userId,
      };

      let params = {
        url: `${Config.V1_API_URL}notification/readNotification`,
        method: "POST",
        data: payLoad,
      };
      setisLoading(true);
      const response = await makeRequest(params);
      // console.log(response);
      if (response) {
        fetchNotifications();
      }
      setisLoading(false);
    } else if (content == "next") {
      window.location.href = "/notification";
    } else {
    }
  };

  useEffect(() => {
    console.log({siteSettings})
    if (props && siteSettings) {
      if(props.pagename != "under-maintenance") {
        // if (
        //   siteSettings &&
        //   siteSettings.message == "Our website is currently under maintenance"
        // ) {
          navigate("/under-maintenance");
        // }
      }
    }
    // if (
    //   props.pagename !== "under-maintenance"
    // ) {
    //   navigate("/under-maintenance");
    // }
  }, [props, siteSettings]);

  return (
    <Navbar
      id="classy-navbar-mobile"
      className="fixed-top"
      key="lg"
      expand="lg"
    >
      <Container fluid className="col-lg-12 px-4">
        <Link className="navbar-brand" to="/">
          {siteSettings && siteSettings.siteLogo ? (
            <img src={siteSettings.siteLogo} alt="logo" id="navbar-img" />
          ) : (
            ""
          )}
        </Link>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              <Link className="navbar-brand" to="/">
                {siteSettings && siteSettings.siteLogo ? (
                  <img src={siteSettings.siteLogo} alt="logo" id="navbar-img" />
                ) : (
                  ""
                )}
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="navbar-nav ms-auto align-items-center text-lg-center">
              <li
                className="nav-item dropdown ps-3"
                style={{ display: "none" }}
              >
                <span
                  className="nav-link dropdown-toggle"
                  id="dappdrop"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Deposit
                </span>
                <ul
                  className="dropdown-menu navbar-top-hover-dropdown"
                  aria-labelledby="dappdrop"
                >
                  <div className="row">
                    <div className="col">
                      <li>
                        <a
                          className="dropdown-item dropdown-item-hover"
                          href="/deposit"
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div className="">
                              <BsBank2 className="top-nav-dropdown-item-icons-1" />
                            </div>
                            <div className="">
                              <p className="top-nav-dropdown-item-text-1">
                                Deposit
                              </p>
                              <p className="top-nav-dropdown-item-text-2">
                                Bank Deposit
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ImArrowRight2 className="top-nav-dropdown-item-icons-2" />
                            </div>
                          </div>
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </li>
              {userLogin === true ? (
                <li className="nav-item ps-3">
                  <a
                    href="javascript:void(0);"
                    onClick={() => navigate("/my/dashboard")}
                    rel="noopener noreferrer"
                    alt="support"
                    className="nav-link"
                  >
                    Dashboard
                  </a>
                </li>
              ) : (
                ""
              )}

              <li className="nav-item ps-3">
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate("/spot/BTC_USDT")}
                  rel="noopener noreferrer"
                  alt="nft"
                  className="nav-link"
                >
                  Exchange
                </a>
              </li>
              <li className="nav-item ps-3">
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate("/markets")}
                  rel="noopener noreferrer"
                  alt="support"
                  className="nav-link"
                >
                  Markets
                </a>
              </li>
              <li className="nav-item dropdown ps-3">
                <span
                  className="nav-link dropdown-toggle"
                  id="dappdrop"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Trade
                </span>
                <ul
                  className="dropdown-menu navbar-top-hover-dropdown"
                  aria-labelledby="dappdrop"
                >
                  <div className="row">
                    <div className="col">
                      <li>
                        <a
                          className="dropdown-item dropdown-item-hover"
                          href="javascript:void(0);"
                          onClick={() => navigate("/spot/BTC_USDT")}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div className="">
                              <BsCurrencyExchange className="top-nav-dropdown-item-icons-1" />
                            </div>
                            <div className="">
                              <p className="top-nav-dropdown-item-text-1">
                                Spot
                              </p>
                              <p className="top-nav-dropdown-item-text-2">
                                Spot exchange
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ImArrowRight2 className="top-nav-dropdown-item-icons-2" />
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item dropdown-item-hover"
                          href="javascript:void(0);"
                          onClick={() => navigate("/trade/all-payments")}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div className="">
                              <BsBank2 className="top-nav-dropdown-item-icons-1" />
                            </div>
                            <div className="">
                              <p className="top-nav-dropdown-item-text-1">
                                P2P
                              </p>
                              <p className="top-nav-dropdown-item-text-2">
                                Peer-To-Peer Exchange
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ImArrowRight2 className="top-nav-dropdown-item-icons-2" />
                            </div>
                          </div>
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown ps-3">
                <span
                  className="nav-link dropdown-toggle"
                  id="dappdrop"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Earn
                </span>
                <ul
                  className="dropdown-menu navbar-top-hover-dropdown"
                  aria-labelledby="dappdrop"
                >
                  <div className="row">
                    <div className="col">
                      <li>
                        <a
                          className="dropdown-item dropdown-item-hover"
                          href="javascript:void(0);"
                          onClick={() => navigate("/comingsoon")}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div className="">
                              <BsCurrencyExchange className="top-nav-dropdown-item-icons-1" />
                            </div>
                            <div className="">
                              <p className="top-nav-dropdown-item-text-1">
                                Stake
                              </p>
                              <p className="top-nav-dropdown-item-text-2">
                                Coming Soon
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ImArrowRight2 className="top-nav-dropdown-item-icons-2" />
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item dropdown-item-hover"
                          href="javascript:void(0);"
                          onClick={() => navigate("/comingsoon")}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div className="">
                              <BsBank2 className="top-nav-dropdown-item-icons-1" />
                            </div>
                            <div className="">
                              <p className="top-nav-dropdown-item-text-1">
                                DeFi Staking
                              </p>
                              <p className="top-nav-dropdown-item-text-2">
                                Coming Soon
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ImArrowRight2 className="top-nav-dropdown-item-icons-2" />
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item dropdown-item-hover"
                          href="javascript:void(0);"
                          onClick={() => navigate("/comingsoon")}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div className="">
                              <ImRocket className="top-nav-dropdown-item-icons-1" />
                            </div>
                            <div className="">
                              <p className="top-nav-dropdown-item-text-1">
                                Launchpad
                              </p>
                              <p className="top-nav-dropdown-item-text-2">
                                Coming Soon
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ImArrowRight2 className="top-nav-dropdown-item-icons-2" />
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item dropdown-item-hover"
                          href="javascript:void(0);"
                          onClick={() => navigate("/comingsoon")}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div className="">
                              <BsBank2 className="top-nav-dropdown-item-icons-1" />
                            </div>
                            <div className="">
                              <p className="top-nav-dropdown-item-text-1">
                                Liquidity Farming
                              </p>
                              <p className="top-nav-dropdown-item-text-2">
                                Coming Soon
                              </p>
                            </div>
                            <div className="ms-auto">
                              <ImArrowRight2 className="top-nav-dropdown-item-icons-2" />
                            </div>
                          </div>
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </li>

              <li className="nav-item ps-3">
                <a
                  href={Config.cryptoEducation}
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="nft"
                  className="nav-link"
                >
                  Crypto Education
                </a>
              </li>
              <li className="nav-item dropdown ps-3 theme-mode-dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  id="dappdrop"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Theme
                </span>
                <ul
                  className="dropdown-menu navbar-top-modes-dropdown"
                  aria-labelledby="dappdrop"
                >
                  <div className="row themes-dropdown-top-section pb-2">
                    <div className="col">
                      <span className="themes-dropdown-text-1">Themes</span>
                    </div>
                  </div>
                  <div
                    className="row switch-modes-section"
                    onChange={modesetter}
                  >
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <img
                          className="mode-image-dropdown-1"
                          src={lightnew}
                          alt="modes"
                        />
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="colortheme"
                            id="flexRadioDefault2"
                            value="light-new"
                            checked={
                              props.theme == "light-new" ? "checked" : ""
                            }
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Light
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <img
                          className="mode-image-dropdown-2"
                          src={Darknew}
                          alt="modes"
                        />
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="colortheme"
                            id="flexRadioDefault4"
                            value="dark-new"
                            checked={props.theme == "dark-new" ? "checked" : ""}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault4"
                          >
                            Dark
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
            </ul>

            <ul className="navbar-nav ms-auto align-items-center text-lg-center">
              {userLogin === false ? (
                <>
                  <li className="nav-item ps-3">
                    <a
                      href="#"
                      onClick={() => navigate("/login")}
                      rel="noopener noreferrer"
                      alt="support"
                      className="nav-link nav-register-button-home"
                    >
                      Log In
                    </a>
                  </li>
                  <li className="nav-item ps-3 text-center">
                    <a
                      href="#"
                      onClick={() => navigate("/register")}
                      rel="noopener noreferrer"
                      alt="support"
                      className="nav-link nav-register-button-home"
                    >
                      Register
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item ps-3 d-lg-none d-blocktop-banner-third-section ">
                    <a
                      href=""
                      onClick={() => navigate("/my/wallet")}
                      rel="noopener noreferrer"
                      alt="support"
                      className="nav-link"
                    >
                      Wallet
                    </a>
                  </li>
                  <li className="nav-item ps-3">
                    <a href="javascript:void(0);" className="nav-link remove">
                      <div className="dropdown home-drop">
                        <button
                          className="dropdown-toggle btn-next-1"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {myProfile && (
                            <>
                              {myProfile.email
                                ? myProfile.email
                                : myProfile.phoneno}{" "}
                            </>
                          )}
                          <FaUserAlt />
                        </button>
                        <ul
                          className="dropdown-menu drop"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href=""
                              onClick={() => navigate("/my/profile")}
                            >
                              <ImProfile /> Profile
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href=""
                              onClick={() => navigate("/my/wallet")}
                            >
                              <AiFillWallet /> Wallet
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href=""
                              onClick={() => navigate("/my/identification")}
                            >
                              <BsShield /> Identification
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href=""
                              onClick={() => navigate("/my/referral")}
                            >
                              <MdOutlineConnectWithoutContact /> Referral
                              Program
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href=""
                              onClick={() => navigate("/my/wallet/history")}
                            >
                              <HiClipboardDocumentList /> Transactions
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <a
                              className="dropdown-item logout"
                              href="javascript:void(0);"
                              onClick={() => {
                                deleteCookie("userToken");
                                setUserProfile();
                                navigate("/login");
                              }}
                            >
                              <BiLogOutCircle /> Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </a>
                  </li>
                </>
              )}
            </ul>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
